<template>
  <div style="height: 100%">
    <router-view v-slot="{ Component, route }">
      <keep-alive>
        <component
          :is="Component"
          :key="route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="route.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
  </div>
</template>

<style>
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background: #f6f7fb;
}

#lang {
  position: fixed;
  top: 28px;
  left: 28px;
}

.lang-img {
  margin-top: 30px;
  width: 40px;
}

.nav {
  height: 100px;
  line-height: 100px;
  font-size: 32px;
  font-weight: 600;
  background-color: #fff;
}

.row-str {
  text-align: left;
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  margin: 30px 0;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.van-toast__text {
  font-size: 28px;
  line-height: 44px;
  word-wrap: break-word;
}
.nan {
  width: 21px !important;
  height: 32px !important;
  border-radius: 0 !important;
}
.nv {
  width: 32px !important;
  height: 32px !important;
  border-radius: 0 !important;
}
</style>
